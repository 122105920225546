import { ReactComponent as MySvg } from '../svg/profile.svg';

function Profile({ data }) {
    return (
        <div className="section qrc_profile_3">
            <div className="qrc_profile_inner">
                <div className="qrc_profilepic" style={{ backgroundImage: `url('${data.imageUrl}')` }}>
                    <MySvg></MySvg>
                </div>
                <div className="qrc_profile_inner_info">
                    <h2>{data.full_name}</h2>
                    <p>{data.title}</p>
                    <p><strong>{data.company.name}</strong></p>
                    <div className="qrc_profile_brand_logo">
                        <img src={data.brand_logo}></img>
                    </div>
                    <div className="qrc_profile_shortcut">
                        <ul>
                            {data.contact_shortcuts.map(item => (
                                <li className="qr_cc_card" key={item.type}>
                                    <a href={`${item.url}${item.value}`}>
                                        <span className={item.icon}></span>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile