function ContactUs({ data, company }) {
    return (
        <div className="section qrc_contact qr_cc_card">
            <div className="qrc_contact_header">
                <div className="qrc_contact_hdr_img" style={{ backgroundImage: `url('https://es.qrcodechimp.com/images/digitalCard/contactus.png')` }}></div>
                <div className="qrc_contact_hdr_text">Contact Us</div>
            </div>
            {data.contact_us.map(item => (
                <div className="qrc_contact_info" key={item.type}>
                    <div className="qrc_contact_info_title">{item.type}</div>
                    <div className="qrc_contact_number"><a href={`${item.url}${item.value}`}>{item.value}</a></div>
                </div>
            ))}
            <div className="qrc_address_info">
                <div className="qrc_address_info_title">Address</div>
                <div className="qrc_address_text">{company.address.street}
                    <br></br>
                    {company.address.city} {company.address.state}
                    <br></br>
                    {company.address.country}
                </div>
                <a className="qrc_direction_btn" href={company.address.geo_location} target="_blank">
                    <span className="icon-direction_1"></span>Direction
                </a>
            </div>
        </div>
    );
}

export default ContactUs