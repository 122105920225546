
function SocialLinks({ data }) {
    return (
        <div className="section qrc_social_links">
            <ul className="qrc_social_links_list">
                {data.social_networks.map(item => (
                    <li className="qr_cc_card" key={item.id}>
                        <a rel="nofollow noopener noreferrer social_link_a" href={item.url} target="_blank">
                            <div className="qrc_social_icon" style={{ backgroundImage: `url('${item.icon}')` }}></div>
                            <div className="qrc_social_text">
                                <div className="qrc_social_text_heading">{item.heading}</div>
                                <div className="qrc_social_text_discription">{item.description}</div>
                            </div>
                            <div className="qrc_social_action">
                                <span className="icon-right_arrow"></span>
                            </div>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}
export default SocialLinks;