import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Profile from '../components/profile';
import dataProfiles from "../db/profiles.json";
import dataCompanies from "../db/companies.json";
import SocialLinks from '../components/social_links';
import ContactUs from '../components/contact_us';
import { downloadVCard, convertImageToBase64 } from '../components/download_vcard';

function VCardPage() {
    const [filteredProfile, setFilteredProfile] = useState();
    const [filteredCompany, setFilteredCompany] = useState();
    const { code } = useParams();

    useEffect(() => {
        // Obtén los parámetros de la URL
        const codeProfile = code; // Parámetro de la URL

        if (codeProfile) {
            // Filtra los usuarios por el nombre
            const filtered = dataProfiles.find(profile =>
                profile.code.includes(codeProfile)
            );
            const company = dataCompanies.find(item =>
                item.code.includes(filtered.codeCompany)
            );

            convertImageToBase64(filtered.imageUrl).then(base64Image => {
                filtered.photo = base64Image; // Aquí tendrás la imagen en formato Base64
            });

            filtered.company = company;
            setFilteredProfile(filtered);

            // console.log(getSocialNetworks(company));
            setFilteredCompany(company);
        } else {
            // setFilteredProfile(dataProfiles); // Si no hay filtro, muestra todos los usuarios
        }
    }, []);

    return (
        <>
            <div className="qr_page_loader" style={{ display: "none" }}>
                <img className="loader_img" src={filteredCompany?.logo}></img>
            </div>
            <div className="qrc_page_wrapper thinScrollBar">
                <div className="pg_background"></div>
                <div className="qrc_page_inner">
                    {filteredProfile ? (<Profile data={filteredProfile}></Profile>) : ""}
                    {filteredProfile ? (<ContactUs data={filteredProfile} company={filteredCompany}></ContactUs>) : ""}
                    {filteredCompany ? (<SocialLinks data={filteredCompany}></SocialLinks>) : ""}
                </div>
                <div className="extra_button_wrapper">
                    {/* <div style={{ display: "flex" }}>
                        <button className="btn " id="btn_page_qr_code">
                            <i className="icon-qrcode"></i>
                        </button>
                        <button className="btn " id="btn_share_page">
                            <i className="icon-file_upload_1"></i>
                        </button>
                    </div> */}
                    <a rel="nofollow noopener noreferrer" href="#" className="qrc_addtocontact" onClick={(event) => { event.preventDefault(); downloadVCard(filteredProfile); }}>
                        <div className="qrc_addtocontact_text">Add to Contact</div>
                        <div className="qrc_addtocontact_circle">
                            <span className="icon-add_1"></span>
                        </div>
                    </a>
                </div>
            </div>
        </>
    );
}

export default VCardPage