const generateVCard = (contactData) => {
  return `BEGIN:VCARD
VERSION:3.0
N;CHARSET=UTF-8:${contactData.full_name.split(" ").slice(-1).join(" ")};${contactData.full_name.split(" ").slice(0, -1).join(" ")};;;
FN;CHARSET=UTF-8:${contactData.full_name}
ORG;CHARSET=UTF-8:${contactData.company.name}
TITLE;CHARSET=UTF-8:${contactData.title}
item2.TEL;type=Mobile Phone:${contactData.contact_us[0].value}
item2.X-ABLabel;CHARSET=UTF-8:Mobile Phone
item3.TEL;type=International Number:${contactData.contact_us[1].value}
item3.X-ABLabel;CHARSET=UTF-8:International Number
item4.EMAIL;type=Email:${contactData.email}
item4.X-ABLabel;CHARSET=UTF-8:Email
item5.ADR;CHARSET=UTF-8:;${contactData.company.address.street};;${contactData.company.address.city};${contactData.company.address.state};;${contactData.company.address.country}
item5.X-ABLabel;CHARSET=UTF-8:Address
item5.X-ABADR:ec
item6.URL:${contactData.company.website}
item6.X-ABLabel;CHARSET=UTF-8:Web Page
${getSocialNetworks(contactData.company)}
PHOTO;ENCODING=b;TYPE=PNG:${contactData.photo}
END:VCARD`;
};

const getSocialNetworks = (data) => {
  let socialNetworks = "";
  socialNetworks = data.social_networks.map((item, index) => {
    return `item${index + 7}.URL;type=${item.type}:${item.url}
item${index + 7}.X-ABLabel;CHARSET=UTF-8:${item.heading}`;
  }).join('\n');
  return socialNetworks;
};

const convertImageToBase64 = async (imageUrl) => {
  try {
    // Obtener la imagen como respuesta
    const response = await fetch(imageUrl);

    // Verificar si la respuesta es válida
    if (!response.ok) {
      throw new Error('Error al obtener la imagen');
    }

    // Obtener el blob de la respuesta
    const blob = await response.blob();

    // Crear un FileReader para leer el blob
    const reader = new FileReader();

    // Retornar una promesa que se resuelve cuando la lectura esté completa
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        // La propiedad result contiene la imagen en formato Data URL
        const base64Data = reader.result.split(',')[1]; // Obtener solo la parte Base64
        resolve(base64Data);
      };

      // Leer el blob como Data URL
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error(error);
    return null; // O manejar el error de otra manera
  }
};

const downloadVCard = (dataJon) => {
  const vCardData = generateVCard(dataJon);
  const blob = new Blob([vCardData], { type: 'text/vcard' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = dataJon.full_name + '.vcf';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url); // Limpiar la URL creada
};

export { downloadVCard, convertImageToBase64 }